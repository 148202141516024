@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #0066CC;
  --secondary: #FF9933;
  --tertiary: #001932;
  --accent: #5c93ff;
  --lightBlue: #ccf6ff;
  --darkOrange: #903e00;
  --lightGray: #E6F2FF;
}

body {
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  
  background-color: var(--lightGray);
}

html {
  scroll-behavior: smooth;
}

.banner {
  background: url("./assets/banner.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.banner02 {
  background: url("./assets/banner02.svg");
  background-color: var(--primary-color);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.banner03 {
  background: url("./assets/banner03.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.banner04 {
  background: url("./assets/banner04.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.fadeLeftMini {
  animation: fadeLeftMini 1s;
  animation-fill-mode: both;
}

.fadeRightMini {
  animation: fadeRightMini 1s;
  animation-fill-mode: both;
}

.navbar {
  background-color: #0b65c6;
  position: fixed;
  left: 50%;
  width: 100%;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  justify-content: space-evenly;
  transition: all 0.3s ease;

}

.floating {
  position: fixed;
  top: 0;
  left: 50;
  right: 0;
  z-index: 1000;
  transform: translateX(-50%);
  width: 80%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.static {
  position: static;
  /* Match with the initial navbar top positioning */
  left: 50%;
  background-color: #216ad9;
  padding-top: 20px;
}

.static a {
  color: white;
}

.floating a img {
  color: var(--tertiary);
  filter: invert(1)
}

.navbar a {
  float: left;
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  transition: background-color 0.3s;
  color: var(--tertiary);
}

.navbar button {
  padding: 15px;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.navbar button:hover {
  background-color: var(--tertiary);
  transition: background-color 0.3s;
}


.navbar a:hover {
  color: var(--secondary);
}

.navbar.hide {
  top: -100px;
  transition: top 0.3s;
}

.navbar.show {
  top: 20px;
  transition: top 0.3s;
}

.navbar-left,
.navbar-middle,
.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-left .logo img {
  height: 60px;
  width: auto;
  vertical-align: middle;
  color: var(--tertiary);
  /* To ensure it aligns nicely with any adjacent text or items */
}

.navbar-middle a,
.navbar-right a {
  padding: 14px 16px;
  text-decoration: none;
  transition: 0.3s;
}

.navbar-middle a:hover,
.navbar-right a:hover {
  color: var(--secondary);
  transition: 0.3s;
}

/* Footer.css */

.footer {
  background-color: #333333;
  color: #FFF;
  display: flex;
  justify-content: space-between;
  padding: 20px 10%;
  padding-top: 200px;
  flex-wrap: wrap;
}

.footer-section {
  width: 20%;
  margin-bottom: 20px;
}

.footer h4 {
  margin-bottom: 20px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li a {
  color: #FFF;
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons a {
  font-size: 20px;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #fff;
}

footer button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s;
}

button:hover {
  color: #fff;
}

/* Mobile responsiveness */
@media screen and (max-width: 600px) {
  .navbar a {
    float: none;
    display: block;
    text-align: left;
  }
}

@keyframes fadeLeftMini {
  0% {
    opacity: 0;
    transform: translate3d(-40%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeRightMini {
  0% {
    opacity: 0;
    transform: translate3d(40%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

.scrollmargin {
  scroll-margin-top: 50px;
}

.scrollmargincontact {
  scroll-margin-top: 150px;
}

.animate-slide {
  animation: slide 10s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-165.2%);
  }
}

.overflow-hidden.relative {
  overflow-x: hidden;
}

.fade-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50px;
  /* Smaller devices */
  z-index: 2;
  pointer-events: none;
  background: linear-gradient(to right, var(--lightGray), rgba(255, 255, 255, 0));
}

.fade-effect::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  /* Smaller devices */
  z-index: 2;
  pointer-events: none;
  background: linear-gradient(to left, var(--lightGray), rgba(255, 255, 255, 0));
}

/* For larger screens */
@media (min-width: 1024px) {

  .fade-effect::before,
  .fade-effect::after {
    width: 100px;
    /* Adjusted for larger devices */
  }
}

.fade-down {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transform: translateY(-1rem);
  transition: opacity 0.3s, transform 0.3s, max-height 0.3s;
}

.fade-down.visible {
  opacity: 1;
  max-height: 500px;
  /* Adjust based on your content */
  transform: translateY(0);
}